import {
  CALC_PARAMS,
  CalcCategoryRao,
  CalcCategoryZriDemo,
  CalcCodeOyatDemo,
  CalcCodeRaoDemo,
  CalcCodeStorageDemo,
  CalcCodeTerritoryDemo,
  CalcCodeWasteWaterReceiverDemo,
  CalcPeriodPotentialDangerDemo,
  CalcTotalActivityRaoDepletedUraniumProductsDemo,
  Dictionary,
  RecalcActivityRadionuclidesDemo,
  RepositoryHandlersDto,
  TABLE_CLASSES,
  TableClasses,
} from "table";
import router from "@/router";
import { SettingsTableAppearanceDto } from "@/modules/settings-appearance/helpers";

export const HEADER_IDS = {
  root: "app-header",
  left: "app-header-left",
  right: "app-header-right",
  bottom: "dynamic-header-bottom",
  menuTwoLevel: "app-menu-two-level",
};

export const APP_ICONS /*: Dictionary<ButtonComponentIcon>*/ = {
  detail: {
    src: "/interface/detail.svg",
    alt: "Детально",
  },
  chatSquare: {
    src: "/interface/chat-square.svg",
    alt: "chat-square",
  },
  chatSquareAccent: {
    src: "/interface/chat-square-accent.svg",
    alt: "chat-square",
  },
  fileTree: {
    src: "/interface/file-tree.svg",
    alt: "file-tree",
  },
  arrowULeftBottom: {
    src: "/interface/arrow-u-left-bottom.svg",
    alt: "←",
  },
  arrowULeftTop: {
    src: "/interface/arrow-u-left-top.svg",
    alt: "←",
  },
  menuDown: {
    src: "/interface/menu-down.svg",
    alt: "↓",
  },
  bxSave: {
    src: "/interface/bx-save.svg",
    alt: "💾",
  },
  vector: {
    src: "/interface/vector.svg",
    alt: "←",
  },
  documentOutline: {
    src: "/interface/document-outline.svg",
    alt: "🗋",
  },
};

export const VOC_CLASSES: TableClasses = {
  ...TABLE_CLASSES,
  row: "table__tr voc-table__tr",
  cell: "table__td voc-table__td",
};

export const CALC_COMPONENTS = [
  {
    title: CALC_PARAMS.CalcCodeRao.header,
    component: CalcCodeRaoDemo,
  },
  {
    title: CALC_PARAMS.CalcCategoryRao.header,
    component: CalcCategoryRao,
  },
  {
    title: CALC_PARAMS.RecalcActivityRadionuclides.header,
    component: RecalcActivityRadionuclidesDemo,
  },
  {
    title: CALC_PARAMS.CalcCategoryZri.header,
    component: CalcCategoryZriDemo,
  },
  {
    title: CALC_PARAMS.CalcCodeWasteWaterReceiver.header,
    component: CalcCodeWasteWaterReceiverDemo,
  },
  {
    title: CALC_PARAMS.CalcCodeOyat.header,
    component: CalcCodeOyatDemo,
  },
  {
    title: CALC_PARAMS.CalcCodeTerritory.header,
    component: CalcCodeTerritoryDemo,
  },
  {
    title: CALC_PARAMS.CalcPeriodPotentialDanger.header,
    component: CalcPeriodPotentialDangerDemo,
  },
  {
    title: CALC_PARAMS.CalcCodeStorage.header,
    component: CalcCodeStorageDemo,
  },
  {
    title: CALC_PARAMS.CalcTotalActivityRaoDepletedUraniumProducts.header,
    component: CalcTotalActivityRaoDepletedUraniumProductsDemo,
  },
];

export const CALC_COMPONENTS_DICT = CALC_COMPONENTS.reduce((dict, value) => {
  dict[value.component.name] = value;
  return dict;
}, {} as Dictionary<typeof CALC_COMPONENTS[0]>);

CALC_COMPONENTS.forEach((calc) => {
  router.addRoute({
    path: `/calc/${calc.component.name}`,
    component: calc.component,
  });
});

export const DEFAULT_DROPDOWN_PROPS = {
  containerWidth: "max-content",
  containerProps: {
    style: {
      padding: "10px",
      gap: "5px",
    },
  },
  mode: "right",
};

export const DEFAULT_LEFT_COLUMN_NAME = "№ п/п";

export const GLOBAL_ATTRS_HANDLERS: RepositoryHandlersDto = {
  before_save: ["trim"],
};

export const DEFAULT_SETTINGS = {
  wordWrap: false,
  edit: true,
  hints: true,

  get tableAppearance() {
    return {} as SettingsTableAppearanceDto;
  },
};
