
import {
  defineComponent,
  PropType,
} from "vue";
import {
  APP_ICONS,
  HEADER_IDS,
} from "@/common/consts";
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  CircleLoader,
  copy,
  dateFormat,
  Dictionary,
  isNullOrUndefined,
  Logger,
  ModalComponent,
  NotificationsService,
  RepositoryReadModelDto,
  RepositoryReadTypeDto,
  TABLE_ICONS,
  TableCell,
  toJS,
  VOC_NAMES_DICT,
  VocDataSet,
  VocValueStatusEnum,
} from "table";
import AppApiService from "@/services/AppApiService/AppApiService";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import {
  VocRepositoryDto,
  VocValueDto,
} from "table/dist/services/Api/types/VocRepositoryDto";

export type EditingVocModalMode = "create" | "edit";

export default defineComponent({
  name: "EditingVocModal",
  props: {
    mode: {
      type: String as PropType<EditingVocModalMode>,
      required: true,
    },
    voc_type: {
      type: String,
      required: true,
    },
    record_id: {
      type: [Number, String],
      required: false,
    },
    vocExtraInfo: {
      type: Object as PropType<VocExtraInfo>,
      required: true,
    },
  },
  components: {
    ButtonComponent,
    CircleLoader,
    ModalComponent,
  },
  emits: ["close", "success"],
  setup() {
    return {
      APP_ICONS,
      HEADER_IDS,
      VocValueStatusEnum,
      dateFormat,
      TABLE_ICONS,
      VOC_NAMES_DICT,
    };
  },
  data() {
    return {
      vocRepositoryDto: null as null | VocRepositoryDto,
      readOnly: true,
      initialData: {} as Dictionary,
      currentData: {} as Dictionary,
      vocValueDto: null as VocValueDto | null,
      open: {},
      error: false,
      init: true,
    };
  },
  async created() {
    try {
      this.vocRepositoryDto = (
        await ApiService.getVocRepository(this.voc_type)
      ).json;
    } catch (e: any) {
      NotificationsService.send({
        type: "error",
        title:
          "Произошла ошибка при загрузке данных из репозитория для справочника",
        text: await ApiHelper.getErrorMessage(e),
      });
      Logger.error({ e });
      this.error = true;
      return;
    }

    await this.initRecordId();
  },
  computed: {},
  watch: {
    record_id() {
      this.initRecordId();
    },
  },
  methods: {
    async initRecordId() {
      this.init = false;
      if (this.mode === "create") {
        this.currentData = this.getInitAttrs();
      }

      this.error = false;
      if (isNullOrUndefined(this.record_id)) {
        this.readOnly = false;
        this.init = true;
        return;
      }

      this.readOnly = true;

      try {
        const vocValueDto = (
          await ApiService.getVocRecord(this.record_id, this.voc_type)
        ).json;
        this.setVocValueDto(vocValueDto);
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при инициализации записи справочника",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        this.error = true;
        return;
      }

      this.init = true;
    },

    getNewVocValueDto() {
      const newVocValueDto: VocValueDto = toJS(this.vocValueDto!);
      VocDataSet.setAttrs(newVocValueDto, this.initialData, this.vocRepositoryDto!);
      if (isNullOrUndefined(newVocValueDto.voc_type)) {
        newVocValueDto.voc_type = this.voc_type;
      }

      return newVocValueDto;
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.currentData[event.model.field] = event.value;
    },

    onCancelEdit() {
      this.currentData = toJS(this.initialData);
      this.readOnly = true;
    },

    onResetField(model: RepositoryReadModelDto) {
      this.currentData[model.field] = this.initialData[model.field];
    },

    async onSaveCurrentVersion(publish = false) {
      if (isNullOrUndefined(this.record_id)) {
        return;
      }

      this.initialData = toJS(this.currentData);
      const newVocValueDto = this.getNewVocValueDto();
      if (publish) {
        newVocValueDto.value_status = VocValueStatusEnum.Active;
      }

      try {
        const result = (
          await AppApiService.updateVocValueDto(this.record_id, newVocValueDto)
        ).json;
        this.setVocValueDto(result);
        this.readOnly = true;
        NotificationsService.send({
          type: "success",
          title: "Статус сохранения",
          text: "Изменения сохранены",
          duration: 5000,
        });
        this.$emit("success");
        this.$emit("close");
      } catch (e) {
        Logger.error({ e });
        const errorMessage = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Статус сохранения",
          text: errorMessage,
        });
      }
    },

    setVocValueDto(vocValueDto: VocValueDto) {
      const attrs = VocDataSet.getAttrs(vocValueDto, this.vocRepositoryDto!) || {};
      this.initialData = toJS(attrs);
      this.currentData = toJS(attrs);
      this.vocValueDto = vocValueDto;
    },

    onEditCurrentVersion() {
      this.readOnly = false;
    },

    async onRemove() {
      if (isNullOrUndefined(this.record_id)) {
        return;
      }

      try {
        const result = (
          await ApiVocService.delete(this.record_id, this.voc_type)
        ).json;
        if (!result) {
          this.$emit("success");
          this.$emit("close");
          return;
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При удалении записи из справочника произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    async onArchive() {
      if (isNullOrUndefined(this.record_id)) {
        return;
      }

      try {
        const newVocValue = (
          await ApiVocService.archive(this.record_id, this.voc_type)
        ).json;
        if (newVocValue) {
          this.setVocValueDto(newVocValue as VocValueDto);
          return;
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При архивировании записи из справочника произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    async onMakeActive() {
      if (isNullOrUndefined(this.record_id)) {
        return;
      }

      try {
        const newVocValue = (
          await ApiVocService.toMakeActive(this.record_id, this.voc_type)
        ).json;
        if (newVocValue) {
          this.setVocValueDto(newVocValue as VocValueDto);
          return;
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title:
            "При попытке сделать запись из справочника активной произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    // РЕЖИМ СОЗДАНИЯ -----------------------------------------------

    getInitAttrs(): Dictionary {
      return this.vocRepositoryDto!.form.table.model.reduce((attrs, model) => {
        attrs[model.field] = TableCell.getDefaultValue(this.getTypeAttr(model));
        return attrs;
      }, {} as Dictionary);
    },

    getTypeAttr(
      model: RepositoryReadModelDto,
    ): RepositoryReadTypeDto | undefined {
      return this.vocRepositoryDto!.types[model.type || ""];
    },

    async createEntry(publish: boolean) {
      let value_status = VocValueStatusEnum.Draft;
      if (publish) {
        value_status = VocValueStatusEnum.Active;
      }

      const vocValueDto: Omit<VocValueDto,
        "record_id" | "valid_from" | "valid_to" | "value_id" | "label"> = {
        voc_type: this.voc_type,
        value_status,
        attrs: {},
      };
      VocDataSet.setAttrs(vocValueDto as VocValueDto, copy(this.currentData), this.vocRepositoryDto!);
      try {
        const result = (await AppApiService.saveVocValueDto(vocValueDto)).json;
        NotificationsService.send({
          type: "success",
          title: "Создание записи в справочнике",
          text: `Запись успешно создана`,
          duration: 5000,
        });
        this.vocValueDto = result;
        this.$emit("success", result);
        this.$emit("close");
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Создание записи в справочнике",
          text: (await ApiHelper.getErrorMessage(e)) || "Неизвестная ошибка",
        });
        Logger.error({ e });
        return;
      }
    },
  },
});
