import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "editing-voc-extended-container" }
const _hoisted_2 = { class: "editing-voc-extended" }
const _hoisted_3 = { class: "editing-voc-extended-header" }
const _hoisted_4 = {
  class: "app-h-s22-h24-w7-c333",
  style: {"margin-bottom":"20px"}
}
const _hoisted_5 = {
  key: 0,
  class: "app-label-s16-h24-w5-c333"
}
const _hoisted_6 = {
  key: 1,
  class: "app-label-s16-h24-w5-c333",
  style: {"display":"flex","gap":"10px"}
}
const _hoisted_7 = {
  key: 1,
  class: "app-h-s22-h24-w7-c333",
  style: {"margin-bottom":"20px"}
}
const _hoisted_8 = { class: "editing-voc-extended-toolbar" }
const _hoisted_9 = { class: "editing-voc-extended__attrs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditingVocHistoryVersions = _resolveComponent("EditingVocHistoryVersions")!
  const _component_TDatePicker = _resolveComponent("TDatePicker")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_EditingVocEntry = _resolveComponent("EditingVocEntry")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    style: { flexGrow: 1 },
    visible: true,
    "body-style": "padding: 0px;",
    "content-style": "border-radius: 0; height: 100%; box-sizing: border-box;",
    onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.vocValueDto)
          ? (_openBlock(), _createBlock(_component_EditingVocHistoryVersions, {
              key: 0,
              "current-voc-value-dto": _ctx.vocValueDto,
              visible: _ctx.open.historyVersions,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open.historyVersions = false)),
              onSelectVersion: _ctx.onSelectVersion
            }, null, 8, ["current-voc-value-dto", "visible", "onSelectVersion"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.mode === 'edit')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.vocValueDto && _ctx.init)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.vocValueDto.label), 1),
                        (_ctx.readOnly)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createTextVNode(" Версия действительна с " + _toDisplayString(_ctx.dateFormat(_ctx.vocValueDto.valid_from, "rusDate")) + " ", 1),
                              (_ctx.vocValueDto.valid_to)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(" ") + "по " + _toDisplayString(_ctx.dateFormat(_ctx.vocValueDto.valid_to, "rusDate")), 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createVNode(_component_TDatePicker, {
                                modelValue: _ctx.vocValueDto.valid_from,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vocValueDto.valid_from) = $event)),
                                readonly: Boolean(_ctx.vocValueDto.edit?.readonly?.valid_from),
                                placeholder: "Версия действительна с"
                              }, null, 8, ["modelValue", "readonly"]),
                              (!_ctx.vocValueDto.edit?.readonly?.valid_to)
                                ? (_openBlock(), _createBlock(_component_TDatePicker, {
                                    key: 0,
                                    modelValue: _ctx.vocValueDto.valid_to,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vocValueDto.valid_to) = $event)),
                                    placeholder: "По"
                                  }, null, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]))
                      ], 64))
                    : (_openBlock(), _createBlock(_component_CircleLoader, { key: 1 }))
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.mode === 'create')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Создание записи в справочнике "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.mode === 'edit' && _ctx.vocValueDto)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_ButtonComponent, {
                    class: "button-blue",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.open.historyVersions = !_ctx.open.historyVersions))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" История версий ")
                    ]),
                    _: 1
                  }),
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.to_archive)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 0,
                        class: "form-button",
                        "data-type": "danger",
                        onClick: _ctx.onArchive
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Архивировать ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.to_make_active)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 1,
                        class: "form-button",
                        onClick: _ctx.onMakeActive
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сделать активной ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnly && _ctx.vocValueDto.edit?.edit)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 2,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onEditCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Редактировать ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (!_ctx.readOnly)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 3,
                        class: "form-button",
                        onClick: _ctx.onCancelEdit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Закрыть без сохранения ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status !== _ctx.VocValueStatusEnum.Draft
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 4,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onSaveCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить текущую версию ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status === _ctx.VocValueStatusEnum.Active
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 5,
                        disabled: _ctx.isEmptyKodOrg,
                        class: "form-button",
                        "data-type": "accent",
                        title: "Заполните код организации и сохраните текущую версию",
                        onClick: _ctx.onSaveNewVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить новую версию ")
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status === _ctx.VocValueStatusEnum.Draft
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 6,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onSaveCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить черновик ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (
                  !_ctx.readOnly &&
                  _ctx.vocValueDto.value_status === _ctx.VocValueStatusEnum.Draft
                )
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 7,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSaveCurrentVersion(true)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить и опубликовать ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.mode === 'create')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_ButtonComponent, {
                    class: "form-button",
                    "data-type": "accent",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.createEntry(false)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Сохранить черновик ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ButtonComponent, {
                    class: "form-button",
                    "data-type": "accent",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.createEntry(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Сохранить и опубликовать ")
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_ButtonComponent, {
              icon: _ctx.TABLE_ICONS.close,
              class: "button-opacity",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close')))
            }, null, 8, ["icon"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.vocRepositoryDto && _ctx.init)
              ? (_openBlock(), _createBlock(_component_EditingVocEntry, {
                  key: 0,
                  "current-data": _ctx.currentData,
                  "default-open-headers": _ctx.mode === 'create',
                  "read-only": _ctx.readOnly,
                  "readonly-object": _ctx.vocValueDto?.edit?.readonly?.attrs,
                  "unique-id": _ctx.voc_type,
                  "voc-extra-info": _ctx.vocExtraInfo,
                  "voc-repository-dto": _ctx.vocRepositoryDto,
                  "voc-type": _ctx.voc_type,
                  onReset: _ctx.onResetField,
                  onChangeField: _ctx.onChangeField
                }, null, 8, ["current-data", "default-open-headers", "read-only", "readonly-object", "unique-id", "voc-extra-info", "voc-repository-dto", "voc-type", "onReset", "onChangeField"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}